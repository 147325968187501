@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@400;600;700&display=swap');
::-webkit-scrollbar {
    width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c3c9cf;
    border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #c3c9cf;
}

.country-select {
    color: #a9b6c6 !important;
}

@font-face {
    font-family: 'Circular Std';
    font-weight: 700;
    src: url(/fonts/Circular-Std/CircularStd-Bold.woff) format('woff');
}

@font-face {
    font-family: 'Circular Std';
    font-weight: bold;
    src: url(/fonts/Circular-Std/CircularStd-Bold.woff) format('woff');
}

@font-face {
    font-family: 'Circular Std';
    font-weight: 900;
    src: url(/fonts/Circular-Std/CircularStd-Black.woff) format('woff');
}

@font-face {
    font-family: 'Circular Std';
    font-weight: 500;
    src: url(/fonts/Circular-Std/CircularStd-Medium.woff) format('woff');
}

@font-face {
    font-family: 'Circular Std';
    font-weight: 400;
    src: url(/fonts/Circular-Std/CircularStd-Book.woff) format('woff');
}

@font-face {
    font-family: 'Circular Std';
    font-weight: 700;
    src: url(/fonts/Circular-Std/CircularStd-Bold.woff) format('woff');
}

@font-face {
    font-family: 'Circular Std';
    font-weight: bold;
    src: url(/fonts/Circular-Std/CircularStd-Bold.woff) format('woff');
}

@font-face {
    font-family: 'Circular Std';
    font-weight: 900;
    src: url(/fonts/Circular-Std/CircularStd-Black.woff) format('woff');
}

@font-face {
    font-family: 'Circular Std';
    font-weight: 500;
    src: url(/fonts/Circular-Std/CircularStd-Medium.woff) format('woff');
}

@font-face {
    font-family: 'Circular Std';
    font-weight: 400;
    src: url(/fonts/Circular-Std/CircularStd-Book.woff) format('woff');
}

@tailwind base;
@tailwind components;

@tailwind utilities;

.alert-danger {
    @apply bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'DM Sans', 'Circular Std', sans-serif;
    /* font-family: Circular Std, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
    line-height: 1.6;
    font-size: 16px;
}

* {
    box-sizing: border-box;
}

a {
    color: #0070f3;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

img {
    max-width: 100%;
    display: block;
}

.tooltip-container {
    max-width: 410px;
    box-shadow: unset !important;
    border-radius: 16px !important;
    overflow: hidden !important;
    border: 0 !important;
    background: white;
    padding: 0 !important;
    z-index: 999999 !important;
}

.tooltip-arrow[data-placement*='bottom']::before {
    border-color: none !important;
}

.ReactModal__Body--open {
    overflow-y: hidden;
}

.text-orange-tangerine {
    color: #e49700;
}

.text-green-elf {
    color: #1ca760;
}

/* region noui*/
.noUi-horizontal {
    height: 12px;
    background: #eaf1f2;
    border-radius: 10px;
    top: -5px;
}

.noUi-handle.noUi-handle-lower {
    width: 30px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 0;
    box-shadow: 0px 2px 4px rgba(102, 123, 154, 0.25);
    border-radius: 100%;
    outline: none;
    border-radius: 15px;
}
.noUi-connects {
    margin-left: 2px;
    border-radius: 10px;
}
.noUi-connect {
    height: 8px;
    top: 2px;
}
.noUi-connect::after {
    content: '';
    z-index: 5;
    width: 3px;
    height: 3px;
}
.noUi-touch-area {
    display: none;
}

/* .noUi-handle::after,
.noUi-handle::before {
    position: unset;
} */

.noUi-handle::after {
    content: url('/images/icons/slider-handle.svg');
    width: 9.6px;
    height: 0;
    background: white;
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
}

[disabled].noUi-target .noUi-connect {
    background: #b8b8b8 !important;
}
.text-inherit {
    color: inherit;
}

.noUi-handle::before {
    content: '';
    display: none;
}

.rc-collapse {
    border: none !important;
    background-color: white !important;
    border-radius: 0 !important;
}

.rc-collapse-content-box {
    margin: 0 !important;
}

.ReactModal__Body--open {
    overflow-y: hidden;
}

.noUi-connect {
    background: linear-gradient(90deg, #0295eb 14.9%, #2dc2e2 100%);
}

.wrap-tiktok-slider .noUi-connect,
.layout-tiktok .noUi-connect {
    background: linear-gradient(90deg, #ff7b54 0.29%, #ffa654 100%);
}

.noUi-target {
    border: 0;
}

.text-green-elf {
    color: #1ca760;
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header .rc-collapse-extra {
    margin-right: 0;
}
.bg-green-tiktokButton {
    background-color: #e8fffd;
}

.slick-track {
    display: flex !important;
}

.slick-slide {
    height: inherit !important;
    outline: none !important;
    margin-bottom: 10px;
    margin-top: 10px;
}

.slick-slide > div {
    height: 100% !important;
}

.rc-collapse-content {
    padding: 0 !important;
}

.min-h-647px {
    min-height: 647px;
}
@media screen and (max-width: 767.98px) {
    .min-h-647px {
        min-height: auto;
    }
    .rc-collapse-content {
        padding: 0 !important;
    }

    .img-folow-twitter img {
        object-fit: contain;
        width: 165px;
    }
    /* mobile slider for tiktok */
    .tiktok .noUi-horizontal,
    .instagram .noUi-horizontal {
        height: 19px;
        background: #e4e9ed;
        border-radius: 10px;
    }
    .tiktok .noUi-connect,
    .instagram .noUi-connect {
        height: 12px;
        top: 3px;
        border-radius: 10px;
        left: 2px;
    }
    .tiktok .noUi-horizontal .noUi-handle,
    .instagram .noUi-horizontal .noUi-handle {
        top: -3px;
    }
    .tiktok .noUi-handle.noUi-handle-lower,
    .instagram .noUi-handle.noUi-handle-lower {
        top: -8px;
        width: 32px;
        height: 32px;
    }
    .tiktok .noUi-handle.noUi-handle-lower::after,
    .instagram .noUi-handle.noUi-handle-lower::after {
        top: 3px;
    }
    .tiktok #arrowAnim,
    .instagram #arrowAnim {
        margin-top: 4px;
    }
    .tiktok .slide-label.text-sm,
    .instagram .slide-label.text-sm {
        font-size: 16px !important;
    }
    /* .tiktok .noUi-handle::before,.tiktok .noUi-handle::after {
        width: 1.37px
    }  */
}
.react-paginate ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
}

.react-paginate li {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
}

.link-active:focus {
    outline: none;
}

.react-paginate .active {
    border: 1px solid #3bbcb3;
    background: #ffffff;
    color: #3bbcb3;
}

.react-paginate .disabled a {
    color: #8f8b8b;
    cursor: not-allowed;
    text-decoration: none;
}

.react-paginate .disabled a:focus {
    outline: none;
}

.grid-container {
    min-height: calc(100vh - 80px);
}
/* tailwindcss not working */
.shadow-tooltip {
    box-shadow: 0px 15px 50px rgba(76, 101, 119, 0.22);
}
.whitespace-nowrap {
    white-space: normal;
}
.border-color-youtube {
    border-color: #f0c015;
}
.bg-youtube-primary {
    background-color: #f0c015;
}
@media screen and (max-width: 767.98px) {
    .rc-collapse-content {
        padding: 0 !important;
    }
    /* tailwindcss not working */
    .whitespace-nowrap {
        white-space: nowrap;
    }
    .grid-container {
        min-height: calc(100vh - 60px);
    }
    .btn-mobile-dashboard {
        width: 97px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
    }
}

.layout-left {
    background: rgba(242, 245, 248, var(--bg-opacity)) !important;
    position: relative;
}
.layout-left::before {
    content: '';
    background: rgba(242, 245, 248, var(--bg-opacity)) !important;
    position: absolute;
    top: 0;
    left: -290%;
    bottom: 0;
    width: 300%;
}
.layout-left > div {
    position: relative;
}

.img-twitter {
    width: 100%;
}
.img-twitter * {
    width: 100%;
}
input:-webkit-autofill,
input:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:focus {
    -webkit-text-fill-background-color: white !important;
    transition: background-color 600000s 0s, color 600000s 0s;
    -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
}
input[data-autocompleted],
select[data-autocompleted] {
    background-color: transparent !important;
}

.bg-gradient-tiktok {
    background: linear-gradient(276.38deg, #ffdbe5 0%, #d7fefd 100%);
}
.bg-gradient-contact {
    background: linear-gradient(156.97deg, #ffffff 6.41%, #e6eaf0 76.7%);
}
.bg-gradient-testimonials {
    background: linear-gradient(
        180deg,
        rgba(235, 237, 240, 0) 0%,
        #ebedf0 53.35%
    );
}
.translate-loading {
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s;
}

@-webkit-keyframes animatebottom {
    from {
        transform: translateY(30px);
    }
    to {
        transform: translateY(0);
    }
}
@keyframes animatebottom {
    from {
        transform: translateY(30px);
    }
    to {
        transform: translateY(0);
    }
}

.backgroundInstagram {
    background: radial-gradient(
        134.37% 134.37% at -13.75% 136.28%,
        rgba(250, 225, 0, 0.6) 0%,
        rgba(250, 220, 4, 0.6) 5.44%,
        rgba(251, 206, 14, 0.6) 11.67%,
        rgba(252, 183, 32, 0.6) 18.29%,
        rgba(254, 152, 56, 0.6) 25.14%,
        rgba(254, 105, 60, 0.6) 30.54%,
        rgba(208, 7, 85, 0.6) 49.22%,
        rgba(113, 0, 255, 0.6) 88.7%
    );
}
.grayscale {
    filter: grayscale(100%);
}
input:focus::placeholder {
    color: transparent;
}
